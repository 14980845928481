import { useState } from "react";
import { ReactImageCarouselViewer } from "react-image-carousel-viewer";
import { Image } from "../../types/types";

export default function Carousel({
  projectName,
  images,
}: {
  projectName: string;
  images: Image[];
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  return (
    <div className="flex gap-4 items-center h-24 md:h-56 overflow-y-scroll">
      {images.map((image, index) => {
        return (
          <img
            loading="lazy"
            key={image.src}
            src={image.src}
            alt={projectName}
            className="w-full h-full"
            onClick={() => {
              setIndex(index);
              setIsOpen(true);
            }}
          />
        );
      })}

      <ReactImageCarouselViewer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        startIndex={index}
        images={images}
      />
    </div>
  );
}
