import { useLocation } from "react-router";

export default function Backdrop() {
  const location = useLocation();
  return (
    <div
      className={`fixed top-0 left-0 opacity-50 bg-black h-full w-full ${
        location.pathname === "/journal" ? "z-40" : null
      }`}
    ></div>
  );
}
