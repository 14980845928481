import { Fragment } from "react";

interface Category {
  id: number;
  value: string;
  label: string;
}

function Filters({
  handleCategory,
  selected,
  filters,
}: {
  handleCategory: (category: Category) => void;
  selected: string;
  filters: Category[];
}) {
  return (
    <ul className="flex items-center md:gap-6 pb-6 pr-2 pt-4">
      {filters.map((filter) => (
        <Fragment key={filter.id}>
          <li
            className={`cursor-pointer p-2 sm:p-0 ${
              selected === filter.value ? "font-bold" : "font-normal"
            }`}
            onClick={() => handleCategory(filter)}
          >
            {filter.label}
          </li>
          {filter.id !== 5 && <span>\</span>}
        </Fragment>
      ))}
    </ul>
  );
}

export default Filters;
