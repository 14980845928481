export const project_categories = [
  {
    id: 1,
    label: "Hospitality",
    value: "hospitality",
  },
  {
    id: 2,
    label: "Houses",
    value: "houses",
  },
  {
    id: 3,
    label: "Multifamily",
    value: "multifamily",
  },
  {
    id: 4,
    label: "Office",
    value: "office",
  },
];

export const journal_categories = [
  {
    id: 1,
    label: "Press",
    value: "press",
  },
  {
    id: 2,
    label: "Film",
    value: "film",
  },
  {
    id: 3,
    label: "Study",
    value: "study",
  },
];
