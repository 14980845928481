import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home/Home";
import Projects from "./pages/Projects/Projects";
import Project from "./pages/Project/Project";
import About from "./pages/About/About";
import Journal from "./pages/Journal/Journal";
import Contact from "./pages/Contact/Contact";

import Layout from "./components/Layout/Layout";

function App() {
  const routes = (
    <Routes>
      <Route path="/contact" element={<Contact />} />
      <Route path="/journal" element={<Journal />} />
      <Route path="/about" element={<About />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/projects/:id" element={<Project />} />
      <Route path="/" element={<Home />} />
    </Routes>
  );

  return (
    <div className="App">
      <Layout>{routes}</Layout>
    </div>
  );
}

export default App;
