import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Disclosure } from "@headlessui/react";

import LightLogo from "../../assets/images/logo-du-white.png";
import DarkLogo from "../../assets/images/logo-du-black.png";
import WhiteHamburgerIcon from "../../assets/images/light-hamburger.svg";
import HamburgerIcon from "../../assets/images/hamburger.svg";
import WhiteCloseIcon from "../../assets/images/white-close.svg";
import CloseIcon from "../../assets/images/close.svg";

import NavItems from "./NavItems/NavItems";
import Backdrop from "../Backdrop/Backdrop";

function Navbar({ isDark }: { isDark?: boolean }) {
  const location = useLocation();
  const [isNavigation, setIsNavigation] = useState(false);

  const displayNavigation = () => setIsNavigation((prevState) => !prevState);

  return (
    <Disclosure
      as="nav"
      className={`z-30 fixed w-full h-100 top-0 left-0 ${
        location.pathname !== "/" ? "bg-white" : "bg-transparent"
      }`}
    >
      {isNavigation && location.pathname === "/" ? <Backdrop /> : null}
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 h-full">
        <div className="relative flex items-center justify-between h-full">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-6">
              <Link to="/">
                {isDark ? (
                  <img
                    className="w-20"
                    src={DarkLogo}
                    alt="Estudio DU"
                    onClick={() => setIsNavigation(false)}
                  />
                ) : (
                  <img
                    className="w-20"
                    src={LightLogo}
                    alt="Estudio DU"
                    onClick={() => setIsNavigation(false)}
                  />
                )}
              </Link>
            </div>
            <div className="flex gap-4 justify-end items-center">
              {isNavigation && location.pathname !== "/" && (
                <NavItems onClickNavItem={displayNavigation} />
              )}
              {isNavigation ? (
                <img
                  src={isDark ? CloseIcon : WhiteCloseIcon}
                  className="w-8 h-8 cursor-pointer"
                  onClick={displayNavigation}
                />
              ) : (
                <img
                  src={isDark ? HamburgerIcon : WhiteHamburgerIcon}
                  className="w-8 h-8 cursor-pointer"
                  onClick={displayNavigation}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {isNavigation && location.pathname === "/" && (
        <NavItems isHome onClickNavItem={displayNavigation} />
      )}
    </Disclosure>
  );
}

export default Navbar;
