import { Fragment, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";

function Layout({ children }: { children: ReactNode }) {
  const location = useLocation();

  return (
    <Fragment>
      <Navbar isDark={location.pathname !== "/"} />
      <main className="p-100 min-h-screen">{children}</main>
      <Footer />
    </Fragment>
  );
}

export default Layout;
