import { PlayIcon, XCircleIcon } from "@heroicons/react/solid";
import ReactPlayer from "react-player";
import Backdrop from "../Backdrop/Backdrop";

export default function Video({
  video,
  isPlayer,
  onClick,
}: {
  video: string;
  isPlayer: boolean;
  onClick: () => void;
}) {
  return (
    <div className="pt-8">
      <button
        onClick={onClick}
        className="px-4 py-2 border bg-white flex items-center justify-between gap-6 font-elzaRegular uppercase"
      >
        <PlayIcon className="h-5 w-5" />
        Play Video
      </button>
      {isPlayer ? (
        <div className="cursor-pointer" onClick={onClick}>
          <Backdrop />
          <div className="fixed z-40 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 drop-shadow-xl rounded-xl p-10 w-full md:w-[650px]">
            <XCircleIcon className="h-10 w-10 text-white float-right cursor-pointer" />
            <ReactPlayer url={video} controls width="100%" />
          </div>
        </div>
      ) : null}
    </div>
  );
}
