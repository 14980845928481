import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import ContactItem from "./ContactItem/ContactItem";
import { IMAGES_URL } from "../../utils";

function Contact() {
  return (
    <div className="px-2 max-w-7xl m-auto">
      <div className="grid grid-cols-3">
        <div className="col-span-3 md:col-span-2">
          <img
            className="w-[80%] pt-10"
            src={`${IMAGES_URL}/studio-du/19.jpg`}
          />
        </div>
        <div className="col-span-3 md:col-span-1">
          <h3 className="font-elzaRegular text-32 text-gray-900">
            Work with us
          </h3>
          <p className="font-elzaRegular text-16 py-6 font-200 text-gray-900">
            Estudio DU is always looking forward to new challenging projects.
            <br />
            <br />
            Please email us for all your enquiries or if you want to have more
            info about our studio.
          </p>
          <Link to="/projects">
            <Button>See our work</Button>
          </Link>
          <ul className="py-16 border-b">
            <li>
              <strong>Buenos Aires</strong>
            </li>
            <li className="text-sm">Cerviño 3808</li>
            <li className="text-sm">C.A.B.A</li>
            <li className="text-sm">+54 (011) 48076860</li>
          </ul>
          <ContactItem topic="General" mail="info@grupodu.com.ar" />
        </div>
      </div>
    </div>
  );
}

export default Contact;
