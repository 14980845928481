import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { projectsData } from "../Projects/projects-data";
import Title from "../../components/Title/Title";
import { useEffect } from "react";
import { ProjectProps } from "../../types/types";
import Button from "../../components/Button/Button";
import GoBack from "../../components/GoBack/GoBack";
import Video from "../../components/Video/Video";
import About from "./About/About";
import Carousel from "../../components/Carousel/Carousel";

export default function Project() {
  const { id } = useParams();
  const idNum = id ? parseInt(id) : 1;
  const navigate = useNavigate();
  const [isPlayer, setIsPlayer] = useState<boolean>(false);
  const [projectToDisplay, setProjectToDisplay] = useState<ProjectProps>({
    id: 0,
    title: "",
    year: "",
    location: "",
    video: "",
    area: "",
    about: "",
    category: {
      id: 0,
      value: "",
      label: "",
    },
    descriptions: [],
    descriptions_t: [],
    gallery: [],
    gallery_t: [],
    image: "",
  });

  useEffect(() => {
    if (id) {
      const isProject = projectsData.filter(
        (project) => project.id === idNum
      )[0];
      setProjectToDisplay(isProject);
    }
  }, [id]);

  const handleButtonClick = () =>
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  const handleClick = () => setIsPlayer((prevState) => !prevState);

  return (
    <div className="px-2 sm:px-6 lg:px-8 max-w-7xl m-auto relative">
      <div className="flex justify-between items-center">
        <Title text={projectToDisplay.title} />
        <h2 className="hidden md:block font-elzaRegular font-300 text-gray-700">
          <span className="uppercase">{projectToDisplay.location}</span>
          <span className="hidden md:block font-freightTextProLight text-right">
            {projectToDisplay.year}
          </span>
          {projectToDisplay.area ? (
            <span className="hidden md:block font-freightTextProLight text-right">
              Area: <i>{projectToDisplay.area}</i>
            </span>
          ) : null}
        </h2>
      </div>
      <div className="w-full flex justify-between">
        <GoBack category={projectToDisplay.category.label} />
      </div>
      <ul className="py-4 md:hidden">
        <li className="text-xs font-elzaThin flex gap-1">
          <span className="font-bold font-elzaRegular">Where: </span>{" "}
          <p className="font-elzaRegular italic text-gray-700 text-xs">
            {projectToDisplay.location}
          </p>
        </li>
        <li className="text-xs font-elzaThin flex gap-1">
          <span className="text-xs font-elzaRegular font-bold">Year:</span>{" "}
          <p className="font-elzaRegular italic text-gray-700 text-xs">
            {projectToDisplay.year}
          </p>
        </li>
        <li className="text-xs font-elzaThin flex gap-1">
          <span className="text-xs font-elzaRegular font-bold">Area:</span>{" "}
          <p className="font-elzaRegular italic text-gray-700 text-xs">
            {projectToDisplay.area}
          </p>
        </li>
      </ul>
      <div className="md:grid grid-cols-3 items-end w-full gap-6 justify-between">
        <div className="col-span-2">
          <img
            className="w-full"
            loading="lazy"
            src={projectToDisplay.image}
            alt={`${projectToDisplay.title} - ${projectToDisplay.category.label} - Estudio DU`}
          />
        </div>
        <div className="py-8 col-span-1">
          {projectToDisplay.about ? (
            <About projectToDisplay={projectToDisplay} />
          ) : null}
        </div>
      </div>
      {projectToDisplay.video ? (
        <Video
          onClick={handleClick}
          isPlayer={isPlayer}
          video={projectToDisplay.video}
        />
      ) : null}
      {projectToDisplay.descriptions.length > 0 ? (
        <div className="py-10 md:columns-2">
          {projectToDisplay.descriptions.map((description) => (
            <p key={description.id} className="py-2 text-18 font-elzaThin">
              {description.text}
            </p>
          ))}
        </div>
      ) : null}
      {projectToDisplay.gallery.length > 0 ? (
        <div className="relative py-10">
          <Carousel
            projectName={projectToDisplay.title}
            images={projectToDisplay.gallery}
          />
        </div>
      ) : null}
      {projectToDisplay.descriptions_t.length > 0 ? (
        <div className="py-10 md:columns-2">
          {projectToDisplay.descriptions_t.map((description) => (
            <p key={description.id} className="py-2 text-18 font-elzaThin">
              {description.text}
            </p>
          ))}
        </div>
      ) : null}
      {projectToDisplay.gallery_t.length > 0 ? (
        <div className="relative py-10">
          <Carousel
            projectName={projectToDisplay.title}
            images={projectToDisplay.gallery_t}
          />
        </div>
      ) : null}
      <div className="w-full flex justify-between gap-6">
        <Button
          onClick={() => {
            if (idNum === 1) return;
            navigate(`/projects/${idNum - 1}`);
          }}
        >
          PREVIOUS PROJECT
        </Button>
        <Button onClick={handleButtonClick}>BACK TO TOP</Button>
        <Button
          onClick={() => {
            if (idNum === 17) return;
            navigate(`/projects/${idNum + 1}`);
          }}
        >
          NEXT PROJECT
        </Button>
      </div>
    </div>
  );
}
