import { journal_categories } from "../../utils/categories";

import prensa1 from "../../assets/images/prensa-1.svg";
import prensa2 from "../../assets/images/prensa-2.svg";
import prensa3 from "../../assets/images/prensa-3.svg";
import prensa4 from "../../assets/images/prensa-4.svg";
import film1 from "../../assets/images/film-1.svg";

export const journalData = [
  {
    id: 1,
    title: "Revista Living",
    category: journal_categories[0],
    journal: "Estilo Rural",
    date: "La Nacion / May 2022",
    image: prensa1,
    video: false,
    link: "https://www.lanacion.com.ar/revista-living/estilo-rural-una-paisajista-y-disenadora-ideo-su-casa-con-espacios-bien-conectados-y-un-jardin-nid01052022/",
  },
  {
    id: 2,
    title: "Revista Living",
    category: journal_categories[0],
    journal: "Evocación de La Toscana",
    date: "La Nacion / July 2021",
    image: prensa2,
    video: false,
    link: "https://www.lanacion.com.ar/revista-living/evocacion-de-la-toscana-una-casona-familiar-proyectada-para-vivir-inmersos-en-el-encanto-de-las-nid04072021/",
  },
  {
    id: 3,
    title: "Clarín",
    category: journal_categories[0],
    journal: "Un refugio para conocer el mundo del vino",
    date: "May 2018",
    image: prensa3,
    video: false,
    link: "https://www.clarin.com/arq/arquitectura/refugio-conocer-mundo-vino_0_rkwdbhrkm.html",
  },
  {
    id: 4,
    title: "Great Wine Capitals",
    category: journal_categories[0],
    journal: "Striking a balance between architecture and terroir",
    date: "December 2019",
    image: prensa4,
    video: false,
    link: "https://www.greatwinecapitals.com/best-of-stories/posada-rosell-boher-striking-a-balance-between-architecture-and-terroir/",
  },
  {
    id: 5,
    title: "Rosell Boher",
    category: journal_categories[1],
    journal: "Where was it published",
    date: "Date by Studio Du",
    image: film1,
    video: true,
    link: "https://www.youtube.com/watch?v=nk3qP5q-0VU",
  },
];
