import { ReactNode } from "react";

function Button({
  children,
  onClick,
}: {
  children: ReactNode | string;
  onClick?: () => void;
}) {
  return (
    <button
      className="uppercase py-1 text-xs px-8 border text-gray-900 font-elzaRegular hover:bg-gray-900 hover:text-white"
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default Button;
