import { Link } from "react-router-dom";
import aboutImage1 from "../../assets/images/about.svg";
import aboutImage2 from "../../assets/images/about1.svg";
import Button from "../../components/Button/Button";
import Text from "../../components/Text/Text";

function About() {
  return (
    <div className="px-2 max-w-7xl m-auto">
      <div>
        <h2 className="text-32 py-8 font-elzaRegular">Our Philosophy</h2>
        <p className="font-elzaRegular text-gray-900 text-32 md:text-60 md:leading-72 pb-10">
          Our office provides an{" "}
          <span className="text-gray-500">
            <i>open creative space</i>
          </span>{" "}
          and friendly environment that caters towards the development of
          various projects.
        </p>
        <p className="font-elzaRegular text-32 md:text-60 md:leading-72">
          The dynamic flow within our team paves the road for a{" "}
          <span className="text-gray-500">
            <i>progressive working climate.</i>
          </span>
        </p>
      </div>
      <div className="w-full py-16">
        <Link to="/projects">
          <Button>See our work</Button>
        </Link>
      </div>
      <div className="pt-10 lg:grid grid-cols-2 gap-6 items-start">
        <div className="col-span-2 lg:col-span-1">
          <p className="pb-4 text-22 font-200">
            The philosophy that has inspired the work has always been the
            pursuit of excellence in each project by creating a quality
            architecture that expresses and excites, that transmits harmony
            through its forms and geometries and, economically, by its
            materiality. An architecture of different scales and environments,
            which reaches the best solution with the elements offered by the
            physical, economic and cultural environment.
          </p>
          <p className="pb-4 text-22 font-200">
            The intervention in existing buildings is conceived after a detailed
            study of the same (its history, structural, structural
            characteristics) that will determine in each case the project.
          </p>
          <p className="pb-4 text-22 font-200">
            The collaboration of a multidisciplinary team, which develops its
            professional work through the pooling of ideas as a constant source
            of creativity and innovation, enriches the results of the project as
            it adds complementary visions to the complex elaboration of a
            project and its subsequent execution.
          </p>
          <img src={aboutImage1} className="pb-4" />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <img src={aboutImage2} className="pb-4 lg:float-right" />
          <div className="max-w-sm lg:float-right">
            <p className="uppercase text-18 lg:text-right text-gray-900 pb-10">
              The study works with projects of different scales but always
              following the same objectives:
            </p>
            <Text
              title="Dialog"
              paragraph="The balanced dialogue of the involved parties injects naturalness and exclusivity into our architecture. The generative idea of space includes the complexity of its variables to make the project recognizable and interpreting spaces in their needs, materials in their senses and forms in their experiences to make the project recognizable and personal."
            />
            <Text
              title="Sustainability and technology"
              paragraph="The architecture is conceived taking into account the environmental impact and assuming the criteria of implementation of the energy efficiency in the design and construction relating in a harmonic way the technological applications."
            />
            <Text
              title="Relationship with the environment"
              paragraph="The integration of the project with the environment through the search of materials and constructive systems of its 'place'."
            />
            <div className="mt-[100px] lg:float-right">
              <Link to="/contact">
                <Button>Contact us</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
