import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import ProjectCard from "../../components/Card/ProjectCard";
import Filters from "../../components/Filters/Filters";
import { projectsData } from "./projects-data";
import { Category } from "../../types/types";
import Loader from "../../components/Loader/Loader";

const categories = [
  { id: 1, value: "all", label: "All" },
  { id: 2, value: "houses", label: "Houses" },
  { id: 3, value: "multifamily", label: "Multifamily" },
  { id: 4, value: "hospitality", label: "Hospitality" },
  { id: 5, value: "office", label: "Office" },
];

function Projects() {
  const [filterActive, setFilterActive] = useState<string>("all");
  const [allProjects, setAllProjects] = useState(projectsData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const onClickCategory = async (category: Category) => {
    setIsLoading(true);
    if (category.value === "all") {
      setAllProjects(projectsData);
      setIsLoading(false);
      setFilterActive(category.value);
      return;
    }
    const filteredProjects = await projectsData.filter(
      (project) => project.category.value === category.value
    );
    setAllProjects(filteredProjects);
    setFilterActive(category.value);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
    navigate(`/projects/${id}`);
  };

  const handleButtonClick = () =>
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="px-2 sm:px-6 lg:px-8 max-w-7xl m-auto relative">
        <div className="fixed w-full z-10 bg-white">
          <Filters
            filters={categories}
            handleCategory={onClickCategory}
            selected={filterActive}
          />
        </div>
        <div className="grid grid-cols-3 gap-4 pb-8 pt-20">
          {allProjects.map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
              handleClick={handleClick}
            />
          ))}
        </div>
        <div className="pt-8 w-full flex justify-center">
          <Button onClick={handleButtonClick}>Back to top</Button>
        </div>
      </div>
    </>
  );
}

export default Projects;
