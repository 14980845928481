import { useEffect, useState } from "react";
import Slider from "../../components/Slider/Slider";
import Loader from "../../components/Loader/Loader";

function Home() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div>
      <Slider />
    </div>
  );
}

export default Home;
