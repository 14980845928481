import InstagramIcon from "../../assets/images/instagram.svg";
import LinkedinIcon from "../../assets/images/linkedin.svg";
import MailIcon from "../../assets/images/mail.svg";

function Footer({ isAbsolute = false }: { isAbsolute?: boolean }) {
  return (
    <footer
      className={`${
        isAbsolute ? "absolute bottom-0" : ""
      } px-2 bg-gray-300 w-full md:h-footer pt-4 md:pt-0`}
    >
      <div className="max-w-7xl m-auto md:flex md:items-center md:justify-between w-full h-full">
        <p className="text-xs font-elzaRegular text-center md:text-left">
          Estudio DU - Desarrollos urbanos - Cerviño 3808 C1425 C.A.B.A.- tel:
          +541148076860- info@grupodu.com.ar
        </p>
        <div className="flex gap-6 items-center justify-center py-4">
          <a
            href="https://www.instagram.com/du.estudio/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={InstagramIcon} className="w-6 h-6" />
          </a>
          <a href="mailto:info@grupodu.com.ar">
            <img src={MailIcon} className="w-6 h-6" />
          </a>
          <img src={LinkedinIcon} className="w-6 h-6" />
          <p className="hidden sm:block text-xs font-elzaRegular">
            ©{new Date().getFullYear()} ESTUDIO DU
          </p>
        </div>
        <p className="sm:hidden text-xs font-elzaRegular text-center pb-4 md:pb-0">
          ©{new Date().getFullYear()} ESTUDIO DU
        </p>
      </div>
    </footer>
  );
}

export default Footer;
