import { Link } from "react-router-dom";

const navigation = [
  { name: "Projects", href: "/projects", current: true },
  { name: "About", href: "/about", current: false },
  { name: "Journal", href: "/journal", current: false },
  { name: "Contact", href: "/contact", current: false },
];

function NavItems({
  isHome,
  onClickNavItem,
}: {
  isHome?: boolean;
  onClickNavItem: () => void;
}) {
  let navbar;
  isHome
    ? (navbar = (
        <div className="px-2 pb-3 space-y-1 h-screen flex flex-col max-w-7xl m-auto pt-10 relative z-20">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              onClick={onClickNavItem}
              className="text-50 text-white font-elzaRegular pt-6 border-b hover:font-freightTextProLightItalic"
              aria-current={item.current ? "page" : undefined}
            >
              {item.name}
            </Link>
          ))}
        </div>
      ))
    : (navbar = (
        <div className="flex gap-2 justify-end md:mt-0 bg-white">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              onClick={onClickNavItem}
              className="text-gray-900 font-elzaRegular hover:font-freightTextProLightItalic"
              aria-current={item.current ? "page" : undefined}
            >
              {item.name}
            </Link>
          ))}
        </div>
      ));

  return navbar;
}

export default NavItems;
