import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Filters from "../../components/Filters/Filters";
import JournalCard from "../../components/Card/JournalCard";
import Button from "../../components/Button/Button";

import { journalData } from "./journal-data";
import { Category } from "../../types/types";

const journal = [
  { id: 1, value: "all", label: "All" },
  { id: 2, value: "press", label: "Press" },
  { id: 3, value: "film", label: "Film" },
  { id: 4, value: "study", label: "Study" },
];

export default function Journal() {
  const [filterActive, setFilterActive] = useState<string>("all");
  const [allJournals, setAllJournals] = useState(journalData);
  const navigate = useNavigate();

  const onClickCategory = async (category: Category) => {
    if (category.value === "all") {
      setAllJournals(journalData);
      setFilterActive(category.value);
      return;
    }
    const filteredProjects = await journalData.filter(
      (journal) => journal.category.value === category.value
    );
    setAllJournals(filteredProjects);
    setFilterActive(category.value);
  };

  const handleClick = (
    e: React.MouseEvent<HTMLDivElement>,
    id: number,
    link?: string
  ) => {
    window.open(link, "_blank");
  };

  return (
    <div className="px-2 sm:px-6 lg:px-8 max-w-7xl m-auto relative">
      <div className="fixed w-full z-10 bg-white">
        <Filters
          filters={journal}
          handleCategory={onClickCategory}
          selected={filterActive}
        />
      </div>
      {allJournals.length === 0 ? (
        <p className="font-elzaRegular text-22">
          We don’t have anything published at the moment.
        </p>
      ) : null}
      <div className="grid grid-cols-3 gap-4 pb-8 pt-20">
        {allJournals.length > 0
          ? allJournals.map((journal) => (
              <JournalCard
                key={journal.id}
                journal={journal}
                handleClick={handleClick}
              />
            ))
          : null}
      </div>
      {allJournals.length === 0 ? (
        <p className="font-elzaRegular text-22">
          We don’t have anything published at the moment.
        </p>
      ) : null}
      <div className="lg:flex gap-6 items-center my-10">
        <Button onClick={() => navigate("/contact")}>CONTACT US</Button>
        <Button onClick={() => navigate("/projects")}>SEE OUR WORK</Button>
      </div>
    </div>
  );
}
