import BackgroundSlider from "react-background-slider";
import { IMAGES_URL } from "../../utils";

const images = [
  `${IMAGES_URL}/general/1.jpg`,
  `${IMAGES_URL}/general/2.jpg`,
  `${IMAGES_URL}/general/3.jpg`,
  `${IMAGES_URL}/general/4.jpeg`,
  `${IMAGES_URL}/general/5.jpg`,
  `${IMAGES_URL}/general/6.jpg`,
  `${IMAGES_URL}/general/7.jpg`,
  `${IMAGES_URL}/general/8.jpg`,
];

function Slider() {
  return <BackgroundSlider images={images} duration={3} transition={1} />;
}

export default Slider;
