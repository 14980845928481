import { ProjectProps } from "../../types/types";

function ProjectCard({
  project,
  handleClick,
}: {
  project: ProjectProps;
  handleClick: (
    e: React.MouseEvent<HTMLDivElement>,
    id: number,
    link?: string
  ) => void;
}) {
  const { id, title, category, image } = project;
  return (
    <div
      className="col-span-3 md:col-span-1 h-320 relative mb-8 cursor-pointer"
      onClick={(e: React.MouseEvent<HTMLDivElement>) => handleClick(e, id)}
    >
      <p className="font-elzaRegular mb-2">
        {title} -{" "}
        <span className="font-freightTextProItalic">{category.label}</span>
      </p>
      <div
        className="w-full h-72 bg-no-repeat bg-cover bg-center"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
    </div>
  );
}

export default ProjectCard;
