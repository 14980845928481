function ContactItem({ topic, mail }: { topic: string; mail: string }) {
  return (
    <p className="py-2">
      <strong>{topic}</strong>
      <br />
      {mail}
    </p>
  );
}

export default ContactItem;
