import { Link } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/outline";

export default function GoBack({ category }: { category: string }) {
  return (
    <div className="text-sm font-500 font-elzaRegular w-40 flex justify-between pb-6 items-center">
      <Link to="/projects">Go Back</Link>{" "}
      <span>
        {" "}
        <ChevronLeftIcon className="h-4 w-4" />{" "}
      </span>{" "}
      <span>{category}</span>
    </div>
  );
}
