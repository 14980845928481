import { useState } from "react";
import { XCircleIcon } from "@heroicons/react/solid";
import ReactPlayer from "react-player";

import Backdrop from "../Backdrop/Backdrop";
import { JournalProps } from "../../types/types";

function JournalCard({
  journal,
  handleClick,
}: {
  journal: JournalProps;
  handleClick: (
    e: React.MouseEvent<HTMLDivElement>,
    id: number,
    link?: string
  ) => void;
}) {
  const [isVideo, setIsVideo] = useState<boolean>(false);
  const { id, title, category, image, link, video } = journal;
  return (
    <>
      <div
        className="col-span-3 md:col-span-1 h-320 relative mb-8 cursor-pointer"
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          !video ? handleClick(e, id, link) : null;
          setIsVideo((prevState) => !prevState);
        }}
      >
        <p className="text-16 font-400 pb-2">{journal.journal}</p>
        <p className="font-elzaRegular mb-2">
          {title} -{" "}
          <span className="font-freightTextProItalic">{category.label}</span>
        </p>
        <div
          className="w-full h-72 bg-no-repeat bg-cover bg-center"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      </div>
      {video && isVideo ? (
        <div className="cursor-pointer" onClick={() => setIsVideo(false)}>
          <Backdrop />
          <div className="fixed z-40 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 drop-shadow-xl rounded-xl p-10 w-full md:w-[650px]">
            <XCircleIcon className="h-10 w-10 text-white float-right cursor-pointer" />
            <ReactPlayer url={link} controls width="100%" />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default JournalCard;
