import { project_categories } from "../../utils/categories";

import { IMAGES_URL } from "../../utils";

const urlForProjects = `${IMAGES_URL}`;

export const projectsData = [
  {
    id: 1,
    title: "Rosell Boher Vineyard Lodge",
    category: project_categories[0],
    image: `${urlForProjects}/general/1.jpg`,
    year: "2013 - 2019",
    location: "Agrelo, Lujan De Cuyo, Mendoza.",
    about:
      "The principal aim of the project is that architecture prioritizes the prominence of landscaping. That’s the reason why we decided to distribute the different uses in several volumes on the ground, seeking the least possible intervention and keeping the visuals open towards the mountains. through this intention, the vineyards, the landscaping, the architecture and places of contemplation are all mixed all over the property.",
    area: "2800 M2",
    video: "",
    descriptions: [
      {
        id: 1,
        text: "One of the main concepts considered during the design process was for guests to be in direct contact with the elements used to produce the wine, that can be touched. The paths from the Main House to the guest’s rooms and then to the rest of the volumes are all surrounded by vineyards. The connection between volumes is an experience itself. This landscape is designed to give shelter and also to let the view to be revealed when is needed, some with mountains and others with valleys.",
      },
    ],
    descriptions_t: [],
    gallery_t: [],
    gallery: [
      { src: `${urlForProjects}/rosell-boher-lodge/1.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/2.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/3.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/4.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/5.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/6.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/7.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/8.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/9.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/10.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/11.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/12.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/13.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/14.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/15.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/16.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/17.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/18.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/19.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/20.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/21.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/22.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/23.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/24.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/25.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/26.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/27.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/28.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/29.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/30.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/31.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/32.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/33.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/34.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/35.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/36.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/37.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/38.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/39.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/40.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/41.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/42.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/43.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/44.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/45.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/46.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/47.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/48.jpg` },
      { src: `${urlForProjects}/rosell-boher-lodge/49.jpg` },
    ],
  },
  {
    id: 2,
    title: "Del Sur",
    category: project_categories[1],
    image: `${urlForProjects}/del-sur/27.jpeg`,
    year: "2021",
    location: "Villa la Angostura, Province of Neuquén.",
    about:
      "Located in Villa la Angostura, a small mountain village in Patagonia, the house was built location within Cumelen, a gated community with views of Lake Nahuel Huapi. The lot has both a coastline on the lake, and a small native forest of coihues and ñires.",
    area: "600 M2",
    video: "",
    descriptions: [
      {
        id: 1,
        text: "Designed to receive family and friends in summer and winter seasons, it was decided to separate each function of the house uses into different volumes, each one taking different directions adapting to the shape of the lot and most important not affecting existing trees. Thus, the house incorporates to the forest.",
      },
      {
        id: 2,
        text: "The connections between the volumes were built as glass boxes bridges, with incredible interaction with the outdoor spaces formed by the shapes of the house. This allows comfortable circulation from the inside and great outdoor result to emphasize the multiple volumes design.",
      },
      {
        id: 3,
        text: "Entirely built with sustainable systems, the walls and roofs are made up of SIP panels, covered on the outside with imitation wood siding and on the inside with oak boards. The floors are made of SPC and PVC windows with hermetic double glazing make the house a comfortable space and generate a very low maintenance home.",
      },
      {
        id: 4,
        text: "The garden was treated to respect and value the native forest and also add native species to keep the idea of ​​living in the middle of a forest. The openings of each space frames the landscape, transforming all of them into paintings.",
      },
    ],
    descriptions_t: [],
    gallery_t: [],
    gallery: [
      { src: `${urlForProjects}/del-sur/1.jpeg` },
      { src: `${urlForProjects}/del-sur/2.jpeg` },
      { src: `${urlForProjects}/del-sur/3.jpeg` },
      { src: `${urlForProjects}/del-sur/4.jpeg` },
      { src: `${urlForProjects}/del-sur/5.jpeg` },
      { src: `${urlForProjects}/del-sur/6.jpeg` },
      { src: `${urlForProjects}/del-sur/7.jpeg` },
      { src: `${urlForProjects}/del-sur/8.jpeg` },
      { src: `${urlForProjects}/del-sur/9.jpeg` },
      { src: `${urlForProjects}/del-sur/10.jpeg` },
      { src: `${urlForProjects}/del-sur/11.jpeg` },
      { src: `${urlForProjects}/del-sur/12.jpeg` },
      { src: `${urlForProjects}/del-sur/13.jpeg` },
      { src: `${urlForProjects}/del-sur/14.jpeg` },
      { src: `${urlForProjects}/del-sur/15.jpeg` },
      { src: `${urlForProjects}/del-sur/16.jpeg` },
      { src: `${urlForProjects}/del-sur/17.jpeg` },
      { src: `${urlForProjects}/del-sur/18.jpeg` },
      { src: `${urlForProjects}/del-sur/19.jpeg` },
      { src: `${urlForProjects}/del-sur/20.jpeg` },
      { src: `${urlForProjects}/del-sur/21.jpeg` },
      { src: `${urlForProjects}/del-sur/22.jpeg` },
      { src: `${urlForProjects}/del-sur/23.jpeg` },
      { src: `${urlForProjects}/del-sur/24.jpeg` },
      { src: `${urlForProjects}/del-sur/25.jpeg` },
      { src: `${urlForProjects}/del-sur/26.jpeg` },
      { src: `${urlForProjects}/del-sur/27.jpeg` },
      { src: `${urlForProjects}/del-sur/28.jpeg` },
      { src: `${urlForProjects}/del-sur/29.jpeg` },
      { src: `${urlForProjects}/del-sur/30.jpeg` },
      { src: `${urlForProjects}/del-sur/31.jpeg` },
      { src: `${urlForProjects}/del-sur/32.jpeg` },
      { src: `${urlForProjects}/del-sur/33.jpeg` },
      { src: `${urlForProjects}/del-sur/34.jpeg` },
      { src: `${urlForProjects}/del-sur/35.jpeg` },
      { src: `${urlForProjects}/del-sur/36.jpeg` },
    ],
  },
  {
    id: 3,
    title: "Hípico",
    category: project_categories[1],
    image: `${urlForProjects}/hipico/25.jpg`,
    year: "2019 - 2020",
    location: "Pilar, Province of Buenos Aires.",
    about:
      "Located in a traditional gated community, on a corner lot, just in front of the Horse-Riding Club, this house is the result style of combining the traditional Italian villas and the current modern constructions. Thus, it is presented with pure volumes, alternating its covers between flat roofs with others with colonial tiles. The moldings, present in all the finishes, are, however, a refined and geometric version of the old ones.",
    area: "450 M2",
    video: "",
    descriptions: [
      {
        id: 1,
        text: "The house resolves the common areas on the ground floor: entrance, living room, family room, dining room, kitchen and a guest bedroom. An attached volume but linked to the house, contains the service (laundry and service room).",
      },
      {
        id: 2,
        text: "Upstairs, the Master Suite gets an entire volume and the children's bedrooms plus a small desk occupy another volume located transversely, between them a circulation space with a flat roof and window sides acts as a connection to the interior and generates a split of volumes from the outside.",
      },
    ],
    descriptions_t: [],
    gallery_t: [],
    gallery: [
      { src: `${urlForProjects}/hipico/1.jpg` },
      { src: `${urlForProjects}/hipico/2.jpg` },
      { src: `${urlForProjects}/hipico/3.jpg` },
      { src: `${urlForProjects}/hipico/4.jpg` },
      { src: `${urlForProjects}/hipico/5.jpg` },
      { src: `${urlForProjects}/hipico/6.jpg` },
      { src: `${urlForProjects}/hipico/7.jpg` },
      { src: `${urlForProjects}/hipico/8.jpg` },
      { src: `${urlForProjects}/hipico/9.jpg` },
      { src: `${urlForProjects}/hipico/10.jpg` },
      { src: `${urlForProjects}/hipico/11.jpg` },
      { src: `${urlForProjects}/hipico/12.jpg` },
      { src: `${urlForProjects}/hipico/13.jpg` },
      { src: `${urlForProjects}/hipico/14.jpg` },
      { src: `${urlForProjects}/hipico/15.jpg` },
      { src: `${urlForProjects}/hipico/16.jpg` },
      { src: `${urlForProjects}/hipico/17.jpg` },
      { src: `${urlForProjects}/hipico/18.jpg` },
      { src: `${urlForProjects}/hipico/19.jpg` },
      { src: `${urlForProjects}/hipico/20.jpg` },
      { src: `${urlForProjects}/hipico/21.jpg` },
      { src: `${urlForProjects}/hipico/22.jpg` },
      { src: `${urlForProjects}/hipico/23.jpg` },
      { src: `${urlForProjects}/hipico/24.jpg` },
      { src: `${urlForProjects}/hipico/25.jpg` },
      { src: `${urlForProjects}/hipico/26.jpg` },
      { src: `${urlForProjects}/hipico/27.jpg` },
      { src: `${urlForProjects}/hipico/28.jpg` },
      { src: `${urlForProjects}/hipico/29.jpg` },
      { src: `${urlForProjects}/hipico/30.jpg` },
      { src: `${urlForProjects}/hipico/31.jpg` },
      { src: `${urlForProjects}/hipico/32.jpg` },
      { src: `${urlForProjects}/hipico/33.jpg` },
      { src: `${urlForProjects}/hipico/34.jpg` },
    ],
  },
  {
    id: 4,
    title: "Viviendas FCT",
    category: project_categories[2],
    image: `${urlForProjects}/viviendas-fct/8.jpg`,
    year: "2021",
    location: "Mendoza city, Rosario city, Santa Fe city",
    about:
      "Multi-family apartment buildings in different provinces of the Argentine Republic. The project is a respond to the housing demand of union members, granting them financed access to their first home in some of the most important cities of Argentina.",
    area: "11500 M2",
    video: "",
    descriptions: [
      {
        id: 1,
        text: "The challenge was to give to all of the projects projects the same identity that can be adapted to the different urban scales, land dimensions and amount of member’s demand. The different buildings are designed following the same premises of language, category and materials, but respecting their singularities depending on each case.",
      },
      {
        id: 2,
        text: "On the facade’s, brick is the main character and is combined with details of black textured plaster on balcony slabs, access and circulations. The choice of materials was aimed at durability over time and low maintenance of the buildings.",
      },
    ],
    descriptions_t: [],
    gallery_t: [],
    gallery: [
      { src: `${urlForProjects}/viviendas-fct/1.jpg` },
      { src: `${urlForProjects}/viviendas-fct/2.jpg` },
      { src: `${urlForProjects}/viviendas-fct/3.jpg` },
      { src: `${urlForProjects}/viviendas-fct/4.jpg` },
      { src: `${urlForProjects}/viviendas-fct/5.jpg` },
      { src: `${urlForProjects}/viviendas-fct/6.jpg` },
      { src: `${urlForProjects}/viviendas-fct/7.jpg` },
      { src: `${urlForProjects}/viviendas-fct/8.jpg` },
      { src: `${urlForProjects}/viviendas-fct/9.jpg` },
    ],
  },
  {
    id: 5,
    title: "Polo",
    category: project_categories[1],
    image: `${urlForProjects}/polo/11.jpg`,
    year: "1995",
    location: "Tortugas Country Club, Pilar, Province of Buenos Aires.",
    about:
      "In a large lot located parallel to one of the main Polo fields of the Tortugas Country Club, where official matches of the Argentinian Polo League are played, this English-style house is located, with exposed brick walls, white trim and steeply sloping slate roofs. The bow-windows, the terraces, skylights and chimney caps ratify the style of the house, associated with the English countryside.",
    area: "1800 M2",
    video: "",
    descriptions: [
      {
        id: 1,
        text: "Accessing from an internal cobbled street, with a roundabout access from which we can enter the house on the ground floor, where the living rooms, the library and the dining room are located. At the extreme of this area a construction entirely made of glass and metal is annexed, as a greenhouse. The rest of the ground floor is completed with the kitchen, daily dining room and playroom. At the ends of these functions, the laundry room is located on one side. In separate volumes, and transverse to the directionality of the house, the garages and service units are located.",
      },
      {
        id: 2,
        text: "Reaching the upper floor, after taking a generous staircase, a common space is located in the manner of a distributor hall for all the bedrooms, four independent suites at the extremes and the Master Suite in the middle. With two dressing rooms and compartmentalized bathroom, the suite also includes the use of a large terrace with views of the polo field.",
      },
      {
        id: 3,
        text: "In a completely exempt volume, the Quincho is located, with an interior grill and dining room, but complemented by functions on the outside such as the clay oven, and a fire pit barbecue. The pool is in relation to this space, surrounded by vegetation. The entire garden is carefully planned and executed to complement the architecture.",
      },
    ],
    descriptions_t: [],
    gallery_t: [],
    gallery: [
      { src: `${urlForProjects}/polo/1.jpg` },
      { src: `${urlForProjects}/polo/2.jpg` },
      { src: `${urlForProjects}/polo/3.jpg` },
      { src: `${urlForProjects}/polo/4.jpg` },
      { src: `${urlForProjects}/polo/5.jpg` },
      { src: `${urlForProjects}/polo/6.jpg` },
      { src: `${urlForProjects}/polo/7.jpg` },
      { src: `${urlForProjects}/polo/8.jpg` },
      { src: `${urlForProjects}/polo/9.jpg` },
      { src: `${urlForProjects}/polo/10.jpg` },
      { src: `${urlForProjects}/polo/12.jpg` },
      { src: `${urlForProjects}/polo/13.jpg` },
      { src: `${urlForProjects}/polo/14.jpg` },
      { src: `${urlForProjects}/polo/15.jpg` },
      { src: `${urlForProjects}/polo/16.jpg` },
      { src: `${urlForProjects}/polo/17.jpg` },
      { src: `${urlForProjects}/polo/18.jpg` },
      { src: `${urlForProjects}/polo/19.jpg` },
      { src: `${urlForProjects}/polo/20.jpg` },
      { src: `${urlForProjects}/polo/21.jpg` },
      { src: `${urlForProjects}/polo/22.jpg` },
      { src: `${urlForProjects}/polo/23.jpg` },
      { src: `${urlForProjects}/polo/24.jpg` },
    ],
  },
  {
    id: 6,
    title: "Rosell Boher Spa",
    category: project_categories[0],
    image: `${urlForProjects}/rosell-boher-spa/8.jpeg`,
    year: "2019",
    location: "Agrelo, Mendoza.",
    about:
      "Its architecture imitates the landscape, taking the color of the earth as a material and rising from it, like a geyser pushing thermal water to the surface through fractures in the ground.",
    area: "350 M2",
    video: "https://www.youtube.com/watch?v=S7fxrlvbAGI",
    descriptions: [
      {
        id: 1,
        text: "Its interiors are covered with overhead light that enters through breaks between the walls and the roofs, resulting in a great intimacy atmosphere.",
      },
      {
        id: 2,
        text: "The Spa is implanted on the edge of a lagoon, the relaxation room has a wide direct view of the valley and the mountain through a big window that raises from the heated water, inviting guests to relax and enjoy.",
      },
    ],
    descriptions_t: [],
    gallery_t: [],
    gallery: [
      { src: `${urlForProjects}/rosell-boher-spa/1.jpg` },
      { src: `${urlForProjects}/rosell-boher-spa/2.jpg` },
      { src: `${urlForProjects}/rosell-boher-spa/3.jpg` },
      { src: `${urlForProjects}/rosell-boher-spa/4.jpg` },
      { src: `${urlForProjects}/rosell-boher-spa/5.jpg` },
      { src: `${urlForProjects}/rosell-boher-spa/6.jpeg` },
      { src: `${urlForProjects}/rosell-boher-spa/7.jpeg` },
      { src: `${urlForProjects}/rosell-boher-spa/8.jpeg` },
      { src: `${urlForProjects}/rosell-boher-spa/9.jpeg` },
      { src: `${urlForProjects}/rosell-boher-spa/10.jpeg` },
    ],
  },
  {
    id: 7,
    title: "Cerro Bayo",
    category: project_categories[0],
    image: `${urlForProjects}/cerro-bayo/4.jpg`,
    year: "2010 - 2011",
    location: "Villa la Angostura, Neuquen.",
    about:
      "The Cerro Bayo ski center modernization project took into consideration the owner’s idea to keep the 'spirit of the hill' intact. Reason that induced us to work with low-scale architecture, with aesthetics of a 'mountain village'. The project includes the base of the ski center, IN OUT houses and hotels and service facilities distributed all around the surface of the mountain. ",
    area: "5800 M2",
    video: "",
    descriptions: [
      {
        id: 1,
        text: "For the commercial base of the ski center, the project is organized so as to invite a journey in constant relationship with the hill and its surroundings. Volumes located surrounding centers or patios are divided in two areas, a gastronomic patio and a commercial center. These centers or patios are scene for meeting points and stage for different events, giving the ski center a unique identity.",
      },
      {
        id: 2,
        text: "The IN OUT houses are placed in almost all of the territory of the Cerro Bayo. Limited access only by specialized mountain vehicles and direct contact with the ski resort lifting facilities. The architecture of straight lines and pure volumes contains interior spaces for contemplation. Views to the Nahuel Huapi lake are discovered through the whole place.",
      },
    ],
    descriptions_t: [],
    gallery_t: [],
    gallery: [
      { src: `${urlForProjects}/cerro-bayo/1.jpg` },
      { src: `${urlForProjects}/cerro-bayo/2.jpg` },
      { src: `${urlForProjects}/cerro-bayo/3.jpg` },
      { src: `${urlForProjects}/cerro-bayo/4.jpg` },
      { src: `${urlForProjects}/cerro-bayo/5.jpg` },
      { src: `${urlForProjects}/cerro-bayo/6.jpg` },
      { src: `${urlForProjects}/cerro-bayo/7.jpg` },
      { src: `${urlForProjects}/cerro-bayo/8.jpg` },
    ],
  },
  {
    id: 8,
    title: "La Chacra",
    category: project_categories[1],
    image: `${urlForProjects}/la-chacra/1.jpg`,
    year: "2014",
    location: "Manuel Alberti – Buenos Aires.",
    about:
      "This house is located in a countryside environment near the city of Buenos Aires. Certain details, such as the access patio surrounded by walls that contain it, the backyard gallery as a space for reunion and transition between inside and outside and the kitchen as a meeting place, let us know about its big countryside style influence in which the house was designed.",
    area: "368 M2",
    video: "",
    descriptions: [
      {
        id: 1,
        text: "The dining room and living space are the central area of ​​the ground floor. The main activities of the family are done there. All the services and private areas were taken to the borders of the house. On the left area we find the master suite and kid’s bedrooms; to the right you enter the kitchen, laundry and service area. On the upper floor with independent access from the outside is the owner private office, with its own terrace, a place with a climate of inspiration, calm and conducive to concentration.",
      },
    ],
    descriptions_t: [],
    gallery_t: [],
    gallery: [
      { src: `${urlForProjects}/la-chacra/2.jpg` },
      { src: `${urlForProjects}/la-chacra/3.jpg` },
      { src: `${urlForProjects}/la-chacra/4.jpg` },
      { src: `${urlForProjects}/la-chacra/5.jpg` },
      { src: `${urlForProjects}/la-chacra/6.jpg` },
      { src: `${urlForProjects}/la-chacra/7.jpg` },
      { src: `${urlForProjects}/la-chacra/8.jpg` },
      { src: `${urlForProjects}/la-chacra/9.jpg` },
      { src: `${urlForProjects}/la-chacra/10.jpg` },
      { src: `${urlForProjects}/la-chacra/11.jpg` },
      { src: `${urlForProjects}/la-chacra/12.jpg` },
      { src: `${urlForProjects}/la-chacra/13.jpg` },
      { src: `${urlForProjects}/la-chacra/14.jpg` },
      { src: `${urlForProjects}/la-chacra/15.jpg` },
      { src: `${urlForProjects}/la-chacra/16.jpg` },
      { src: `${urlForProjects}/la-chacra/17.jpg` },
      { src: `${urlForProjects}/la-chacra/18.jpg` },
    ],
  },
  {
    id: 9,
    title: "Estudio DU",
    category: project_categories[3],
    image: `${urlForProjects}/studio-du/14.jpg`,
    year: "2006 - 2007",
    location: "Palermo, City of Buenos Aires.",
    about:
      "This project is the result of the search for a new location to the estudio DU office. The building is located in the corner of a boulevard street. Built around 1930, the building complex consisted of housing units and on the ground floor a commercial space. The main idea is to maintain the structure of the building, opening the spaces, updating and integrating the new professional office space.",
    area: "350 M2",
    video: "",
    descriptions: [
      {
        id: 1,
        text: "The office is developed on the first floor as a general integrated workshop space, with access from both sides of the corner, with a symmetrical distribution of fixed positions, administration and meeting areas, with office and restrooms.",
      },
      {
        id: 2,
        text: "On the second level there is a large area for a meeting room and presentations for diverse and flexible use.",
      },
      {
        id: 3,
        text: "The activities finish in the facilities of the last level, with an upper back-office, a dining room arranged towards a terrace with a deck floor, which takes up the entire corner with wide long views towards the boulevard that ends in the Eco-Parque, forming a location of Roof-Garden that provides a high value of urban landscape, from the neighborhood corner situation.",
      },
    ],
    gallery: [
      { src: `${urlForProjects}/studio-du/1.jpg` },
      { src: `${urlForProjects}/studio-du/2.jpg` },
      { src: `${urlForProjects}/studio-du/3.jpg` },
      { src: `${urlForProjects}/studio-du/4.jpg` },
      { src: `${urlForProjects}/studio-du/5.jpg` },
      { src: `${urlForProjects}/studio-du/6.jpg` },
      { src: `${urlForProjects}/studio-du/7.jpg` },
      { src: `${urlForProjects}/studio-du/8.jpg` },
      { src: `${urlForProjects}/studio-du/9.jpg` },
      { src: `${urlForProjects}/studio-du/10.jpg` },
      { src: `${urlForProjects}/studio-du/11.jpg` },
      { src: `${urlForProjects}/studio-du/12.jpg` },
      { src: `${urlForProjects}/studio-du/13.jpg` },
      { src: `${urlForProjects}/studio-du/14.jpg` },
      { src: `${urlForProjects}/studio-du/15.jpg` },
      { src: `${urlForProjects}/studio-du/16.jpg` },
      { src: `${urlForProjects}/studio-du/17.jpg` },
      { src: `${urlForProjects}/studio-du/18.jpg` },
      { src: `${urlForProjects}/studio-du/19.jpg` },
      { src: `${urlForProjects}/studio-du/20.jpg` },
      { src: `${urlForProjects}/studio-du/21.jpg` },
    ],
    descriptions_t: [],
    gallery_t: [],
  },
  {
    id: 10,
    title: "Jean-Pierre Cable Car",
    category: project_categories[0],
    image: `${urlForProjects}/telecabina-bayo/6.jpg`,
    year: "2010 - 2012",
    location: "Villa la Angostura, Neuquen.",
    about:
      "One of the priorities of the modernization plan for Cerro Bayo was to increase the number of passengers per hour, building new lifts and modernizing the existing ones, to improve accessibility to the ski center. We thought of the design of the station buildings with a language of industrial architecture, which can dialogue in harmony with the architecture of a mountain village, and at the same time give identity to the envelope of the elevation structure facility. The entrances of overhead light give amplitude to the space.",
    area: "3000 M2",
    video: "",
    descriptions: [
      {
        id: 1,
        text: "The structure of laminated profiles and trusses were covered in sheet metal, the project contemplates a stone basement to guarantee the best maintenance of the buildings towards the impact of the climate. The work represented a challenge, paths were traced to access the top taking the footprints of the tracks, in order to respect the vegetation and generate the fewer environmental impact.",
      },
    ],
    descriptions_t: [],
    gallery_t: [],
    gallery: [
      { src: `${urlForProjects}/telecabina-bayo/1.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/2.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/3.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/4.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/5.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/6.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/7.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/8.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/9.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/10.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/11.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/12.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/13.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/14.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/15.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/16.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/17.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/18.jpg` },
      { src: `${urlForProjects}/telecabina-bayo/19.jpg` },
    ],
  },
  {
    id: 11,
    title: "Ravignani",
    category: project_categories[2],
    image: `${urlForProjects}/ravignani/1.jpg`,
    year: "2022",
    location: "Palermo, Cuidad de Buenos Aires.",
    about:
      "Short-term renting apartment building located in the heart of Palermo, with a commercial space suitable for a restaurant on the ground floor, swimming pool, coworking terrace and quincho and two floors basement garages.",
    area: "3000 M2",
    video: "https://www.youtube.com/watch?v=OoIxHRj-cUg",
    descriptions: [
      {
        id: 1,
        text: "The geometry of the building is the result of combining volumes of concrete with different terraces and balconies sizes, generating overhangs and multiple depths in the façade.",
      },
      {
        id: 2,
        text: "The studio apartments are developed from the first to the third floor. The access to the apartments is all connected on an internal patio that bring natural light and vegetation to the entrance of each unit.",
      },
      {
        id: 3,
        text: "The project has different meeting and recreation spaces. The garden in the backyard has an elevated swimming pool to give privacy to the solarium from the common spaces at the ground floor.  the structure of the pool is used as a roof for a fully equipped quincho. This space is illuminated through an English courtyard. The rooftop has a salon for coworking space and bar and exterior terrace.",
      },
    ],
    descriptions_t: [],
    gallery_t: [],
    gallery: [
      { src: `${urlForProjects}/ravignani/2.jpg` },
      { src: `${urlForProjects}/ravignani/3.jpg` },
      { src: `${urlForProjects}/ravignani/4.jpg` },
      { src: `${urlForProjects}/ravignani/5.jpg` },
      { src: `${urlForProjects}/ravignani/6.jpg` },
      { src: `${urlForProjects}/ravignani/7.jpg` },
      { src: `${urlForProjects}/ravignani/8.jpg` },
    ],
  },
  {
    id: 12,
    title: "Pahiuen",
    category: project_categories[1],
    image: `${urlForProjects}/paihuen/11.jpg`,
    year: "2021",
    location: "Villa la Angostura, Province of Neuquen.",
    about:
      "Inserted in the middle of a native forest in Paihuen, a small sector within a gated community located in Villa La Angostura, this house was designed for a young family willing to receiving family and friends during the holidays seasons, distributed in three volumes to separate the living spaces with the family bedrooms and an independent area comfort and privacy for the guests.",
    area: "350 M2",
    video: "",
    descriptions: [
      {
        id: 1,
        text: "The main house is organized in an 'L' shape, with the access as the connection of two volumes. On the short side the main living space, the kitchen, dining and living room. Connected to this area a small guest house with two suites is connected to the main volume through the quincho, that also serves as a space for daily use and union between the family and its visitors.",
      },
      {
        id: 2,
        text: "On a perpendicular volume the private rooms were located, playroom; service area, children's bedrooms and bathroom and the Master Suite with dressing room and bathroom.",
      },
      {
        id: 3,
        text: "Built with SIP panels, covered with materials such as fiber cement exterior siding and interior oak boards, the house, despite its length, maintains the appearance of a cabin with its steeply roofs.",
      },
      {
        id: 4,
        text: "The interiors are design with a minimalist cabin criterion of natural light-colored materials. With important opening through the windows the forest integrates to the interior, letting the nature be the main character.",
      },
    ],
    descriptions_t: [],
    gallery_t: [],
    gallery: [
      { src: `${urlForProjects}/paihuen/1.jpg` },
      { src: `${urlForProjects}/paihuen/2.jpg` },
      { src: `${urlForProjects}/paihuen/3.jpg` },
      { src: `${urlForProjects}/paihuen/4.jpg` },
      { src: `${urlForProjects}/paihuen/5.jpg` },
      { src: `${urlForProjects}/paihuen/6.jpg` },
      { src: `${urlForProjects}/paihuen/7.jpg` },
      { src: `${urlForProjects}/paihuen/8.jpg` },
      { src: `${urlForProjects}/paihuen/9.jpg` },
      { src: `${urlForProjects}/paihuen/10.jpg` },
      { src: `${urlForProjects}/paihuen/12.jpg` },
      { src: `${urlForProjects}/paihuen/13.jpeg` },
      { src: `${urlForProjects}/paihuen/14.jpeg` },
      { src: `${urlForProjects}/paihuen/15.jpeg` },
      { src: `${urlForProjects}/paihuen/16.jpeg` },
      { src: `${urlForProjects}/paihuen/17.jpeg` },
      { src: `${urlForProjects}/paihuen/18.jpeg` },
      { src: `${urlForProjects}/paihuen/19.jpeg` },
      { src: `${urlForProjects}/paihuen/20.jpeg` },
      { src: `${urlForProjects}/paihuen/21.jpeg` },
      { src: `${urlForProjects}/paihuen/22.jpeg` },
      { src: `${urlForProjects}/paihuen/23.jpeg` },
      { src: `${urlForProjects}/paihuen/24.jpeg` },
      { src: `${urlForProjects}/paihuen/25.jpeg` },
      { src: `${urlForProjects}/paihuen/26.jpeg` },
      { src: `${urlForProjects}/paihuen/27.jpeg` },
      { src: `${urlForProjects}/paihuen/28.jpeg` },
      { src: `${urlForProjects}/paihuen/29.jpeg` },
      { src: `${urlForProjects}/paihuen/30.jpeg` },
      { src: `${urlForProjects}/paihuen/31.jpeg` },
      { src: `${urlForProjects}/paihuen/32.jpeg` },
      { src: `${urlForProjects}/paihuen/33.jpeg` },
      { src: `${urlForProjects}/paihuen/34.jpeg` },
      { src: `${urlForProjects}/paihuen/35.jpeg` },
      { src: `${urlForProjects}/paihuen/36.jpeg` },
    ],
  },
  {
    id: 13,
    title: "Golf",
    category: project_categories[1],
    image: `${urlForProjects}/golf/2.jpeg`,
    year: "2010",
    location: "Villa la Angostura, Province of Neuquen.",
    about:
      "Mountain cabin, implanted in a valley surrounded by forest on the edge of an artificial lagoon where you can breathe calm and the sound of water invites you to relax.",
    area: "115 M2",
    video: "https://www.youtube.com/watch?v=spFCIfjk8F8",
    descriptions: [
      {
        id: 1,
        text: "Its open gabled roof and single rectangular volume are the characteristic of the architectural proposal. Its shorter face finishes off the main space with a large window overlooking the top of the ski center.",
      },
      {
        id: 2,
        text: "Outside, the fire spaces provide an alternative to meeting and contemplating the landscape. The interior clad in wood throughout its envelope gives warmth and sobriety to the space. Its materials were chosen seeking resistance, low maintenance given the particular climate conditions and respecting the image of a mountain village that distinguishes the town of Villa la Angostura.",
      },
    ],
    descriptions_t: [],
    gallery_t: [],
    gallery: [
      { src: `${urlForProjects}/golf/1.jpg` },
      { src: `${urlForProjects}/golf/3.jpeg` },
      { src: `${urlForProjects}/golf/4.jpeg` },
      { src: `${urlForProjects}/golf/5.jpg` },
    ],
  },
  {
    id: 14,
    title: "Toscana",
    category: project_categories[1],
    image: `${urlForProjects}/toscana/23.jpg`,
    year: "2011 - 2014",
    location: "Pilar, Province of Buenos Aires.",
    about:
      "The challenge, from two empty lots, is to generate a modern house in its construction (reinforced concrete, masonry and roof combining metal structure with tiles) and in its comfort (air conditioning, heating, home automation) but maintaining the essence of a traditional Tuscan villa.",
    area: "2500 M2",
    video: "",
    descriptions: [
      {
        id: 1,
        text: "The house has the social areas on the ground floor, accessing from a driveway with a cobble stone pavement in different shades, you enter the Great Hall with an imperial staircase with two branches. To the left of the entrance, the toilet, the office, the cinema, the playroom, the living room, then the dining room, to finish this hourly journey with the daily dining room that opens onto the kitchen and the pantry. On the upper floor, there are three bedrooms on the sides for the children plus one for guests, and in the back, the Master Suite, with its two independent bathrooms and dressing rooms, one for each of the couple.",
      },
      {
        id: 2,
        text: "In the basement are the wine cellar, the garage and the service areas (laundry room and service room).",
      },
      {
        id: 3,
        text: "A separate volume contains the quincho on the ground floor with an interior and exterior grill and on the upper floor a guest bedroom and a gym.",
      },
      {
        id: 4,
        text: "The pool, designed as a pond, has raised edges and a dark green colored texture that reflect the sky. The garden was planted from scratch with large trees to frame the house and enhance the original idea, so that it is not perceived as a new house.",
      },
    ],
    descriptions_t: [],
    gallery_t: [],
    gallery: [
      { src: `${urlForProjects}/toscana/1.jpg` },
      { src: `${urlForProjects}/toscana/2.jpg` },
      { src: `${urlForProjects}/toscana/3.jpg` },
      { src: `${urlForProjects}/toscana/4.jpg` },
      { src: `${urlForProjects}/toscana/5.jpg` },
      { src: `${urlForProjects}/toscana/6.jpg` },
      { src: `${urlForProjects}/toscana/7.jpg` },
      { src: `${urlForProjects}/toscana/8.jpg` },
      { src: `${urlForProjects}/toscana/9.jpg` },
      { src: `${urlForProjects}/toscana/10.jpg` },
      { src: `${urlForProjects}/toscana/11.jpg` },
      { src: `${urlForProjects}/toscana/12.jpg` },
      { src: `${urlForProjects}/toscana/13.jpg` },
      { src: `${urlForProjects}/toscana/14.jpg` },
      { src: `${urlForProjects}/toscana/15.jpg` },
      { src: `${urlForProjects}/toscana/16.jpg` },
      { src: `${urlForProjects}/toscana/17.jpg` },
      { src: `${urlForProjects}/toscana/18.jpg` },
      { src: `${urlForProjects}/toscana/19.jpg` },
      { src: `${urlForProjects}/toscana/20.jpg` },
      { src: `${urlForProjects}/toscana/21.jpg` },
      { src: `${urlForProjects}/toscana/22.jpg` },
      { src: `${urlForProjects}/toscana/23.jpg` },
      { src: `${urlForProjects}/toscana/24.jpg` },
      { src: `${urlForProjects}/toscana/25.jpg` },
      { src: `${urlForProjects}/toscana/26.jpg` },
      { src: `${urlForProjects}/toscana/27.jpg` },
      { src: `${urlForProjects}/toscana/28.jpg` },
      { src: `${urlForProjects}/toscana/29.jpg` },
      { src: `${urlForProjects}/toscana/30.jpg` },
      { src: `${urlForProjects}/toscana/31.jpg` },
      { src: `${urlForProjects}/toscana/32.jpg` },
      { src: `${urlForProjects}/toscana/33.jpg` },
      { src: `${urlForProjects}/toscana/34.jpg` },
    ],
  },
  {
    id: 15,
    title: "Angostura Golf",
    category: project_categories[0],
    image: `${urlForProjects}/angostura-golf/1.jpg`,
    year: "2010 - 2014",
    location: "Villa La Angostura, Neuquen.",
    about:
      "This big scale project has the important responsibility of shaping the growth of more than half of the border of Villa la Angostura town. The main attraction of this new area of the town is a professional golf course, designed to incorporates itself to the environment with a low-impact result.  Golf and club house facilities are placed at the center of the course and hosing condos are built in this area. This sector works as the center of this urban project, a lifting cable car station, including a commercial center, starts at this point all the way up to the top of Cerro Bayo Ski Resort. More housing solutions are distributed all around the project. houses, hotels, apartment buildings and social housing solution.",
    area: "345 HA",
    video: "https://youtu.be/spFCIfjk8F8",
    descriptions: [
      {
        id: 1,
        text: "The golf course is developed on both sides of a stream, one of the most important freshwater courses in Villa la Angostura and which contains the Inacayal waterfall, a significant point of tourist attraction. The access to the project is achieved by the extension of one of the main roads of the town's map, Bulevard Pascotto.",
      },
    ],
    descriptions_t: [],
    gallery_t: [],
    gallery: [
      { src: `${urlForProjects}/angostura-golf/1.jpg` },
      { src: `${urlForProjects}/angostura-golf/2.jpg` },
      { src: `${urlForProjects}/angostura-golf/3.jpg` },
      { src: `${urlForProjects}/angostura-golf/4.jpg` },
      { src: `${urlForProjects}/angostura-golf/5.jpg` },
      { src: `${urlForProjects}/angostura-golf/6.jpg` },
      { src: `${urlForProjects}/angostura-golf/7.jpg` },
      { src: `${urlForProjects}/angostura-golf/8.jpg` },
      { src: `${urlForProjects}/angostura-golf/9.jpg` },
      { src: `${urlForProjects}/angostura-golf/10.jpg` },
      { src: `${urlForProjects}/angostura-golf/11.jpg` },
    ],
  },
  {
    id: 16,
    title: "Aguada de las Alturas",
    category: project_categories[1],
    image: `${urlForProjects}/aguada-de-las-alturas/3.jpg`,
    year: "",
    location: "",
    about: "",
    area: "",
    video: "",
    descriptions_t: [],
    gallery_t: [],
    descriptions: [],
    gallery: [
      { src: `${urlForProjects}/aguada-de-las-alturas/1.jpg` },
      { src: `${urlForProjects}/aguada-de-las-alturas/2.jpg` },
      { src: `${urlForProjects}/aguada-de-las-alturas/4.jpg` },
      { src: `${urlForProjects}/aguada-de-las-alturas/5.jpg` },
      { src: `${urlForProjects}/aguada-de-las-alturas/6.jpg` },
      { src: `${urlForProjects}/aguada-de-las-alturas/7.jpg` },
    ],
  },
  {
    id: 17,
    title: "Pasteur",
    category: project_categories[1],
    image: `${urlForProjects}/pasteur/00.jpg`,
    year: "2022",
    location: "Martinez, Province of Buenos Aires.",
    about:
      "The lot is located in a natural slope just a few meters of the Río de la Plata. With a direct view to the river and the skyline of the City of Buenos Aires, the house was designed to respond to the two well-differentiated fronts of the property. Towards the upper street, on a neighborhood scale, the access was located at the corner of the fence. The front garden has courtesy garages and entrance to the main volume of the house on the ground and upper floors. Towards the slope of the natural terrain, the expansions, pool, the fire pit and in a more abrupt drop, the tennis court and the house of the landlord, everything is located.",
    area: "1000 M2",
    video: "https://www.youtube.com/watch?v=kPCxFRez-ZM",
    descriptions: [
      {
        id: 1,
        text: "Upon entering, there is a view of the longitudinal pond located outside that cross perpendicularly the infinite edge pool, mixing with the river on the horizon. The social areas such as cinema, living room, dining room, kitchen and playroom alternate surrounding a central patio that provides light and allows vegetation to be brought to the center of the house.",
      },
      {
        id: 2,
        text: "On the upper floor, the guest bedrooms are located towards the front and the two suite bedrooms for the children, plus the Master Suite with a dressing room and 2 bathrooms, are located towards the river.",
      },
      {
        id: 3,
        text: "On the terrace, a hall with a toilet and an office articulates the space between two uses, towards the front, the gym and towards the river, a multipurpose room is placed with the best views, through the top of the trees and the neighboring houses on all sides, the river and the Buenos Aires skyline, are absolute main characters on this area.",
      },
      {
        id: 4,
        text: "Using the slope as an advantage, the house has a basement with the service area (laundry room + dependencies), an outdoor bathroom for the pool, the massage room and the mep room.",
      },
      {
        id: 5,
        text: "In the garden, also taking advantage of the ravine, a lateral sector was used to locate a generous staircase that allows descending the steep slope in a comfortable way and contemplating the vegetation, so that when you reach the bottom you can access a tennis court with synthetic grass that allows for other sports and training.",
      },
    ],
    descriptions_t: [],
    gallery_t: [],
    gallery: [
      { src: `${urlForProjects}/pasteur/01.jpg` },
      { src: `${urlForProjects}/pasteur/02.jpg` },
      { src: `${urlForProjects}/pasteur/03.jpg` },
      { src: `${urlForProjects}/pasteur/04.jpg` },
      { src: `${urlForProjects}/pasteur/05.jpg` },
      { src: `${urlForProjects}/pasteur/06.jpg` },
      { src: `${urlForProjects}/pasteur/07.jpg` },
      { src: `${urlForProjects}/pasteur/08.jpeg` },
      { src: `${urlForProjects}/pasteur/09.jpeg` },
      { src: `${urlForProjects}/pasteur/10.jpeg` },
    ],
  },
];
