function Text({ title, paragraph }: { title: string; paragraph: string }) {
  return (
    <div className="text-gray-900">
      <p className="text-22 lg:text-right pb-3">{title}</p>
      <p className="lg:text-right font-200 pb-3">{paragraph}</p>
    </div>
  );
}

export default Text;
